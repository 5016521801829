<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <!--
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-product-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="onRowSelected"
          @row-dblclicked="openDetails"
      >

        <!-- Columns -->
        <template #cell(company_id)="data">
<!--          <b-form-checkbox-->
<!--              name="company-id"-->
<!--              v-model="checked"-->
<!--              :value="data.item"-->
<!--          >-->
            #{{data.item.company_id}}

<!--          </b-form-checkbox>-->
        </template>

        <!-- Column: Type -->
        <template #cell(company_type)="data">
          {{getCodeLabel('company_type', data.item.company_type)}}
        </template>

        <template #cell(user_id)="data">
          {{getCodeLabel('user', data.item.user_id)}}
        </template>


        <!--        company_class-->
        <template #cell(company_class)="data">
          {{getCodeLabel('company_class', data.item.company_class)}}
        </template>

        <!--        company_status-->
        <template #cell(status)="data">
          {{getCodeLabel('company_status', data.item.status)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link>
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-company-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-company-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions} from '@core/utils/filter'
import companyUseList from './CompanyUseSelect'
import companyStore from '@/views/apps/company/companyStore'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selected: [],
    }
  },

  props:{
    companyType :{
      type : String,
      default : ""
    },
    warehouseId:{
      type:Number,
      default:0
    },
    warehouseIdFrom:{
      type:Number,
      default:0
    },
    warehouseIdTo:{
      type:Number,
      default:0
    },
    storeId:{
      type:Number,
      default:0
    },
    showAllLogistics:{
      type:Boolean,
      default:true
    },
    isAllState:{
      type:Boolean,
      default: false
    },
    companyStoreId:{
      type:Number,
      default:0
    },
    all:{
      type:Number,
      default:0
    },
    status:{
      type:Number,
      default:0
    },
    type:{
      type:Number,
      default:0
    },
    query_store:{
      type:Number,
      default:0
    },
    setting_ban_type:{
      type:Number,
      default:0
    },
  },
  methods: {
    getSelected: function() {

      return this.selected
    },
    onRowSelected(items) {

      this.selected = items
      // console.log("onRowSelected")
      // console.log(this.selected[0].company_name)
    },
    openDetails(item){
      this.$emit("table",item)
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('company')) store.unregisterModule('company')
    })


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      // resolveProductTypeCode,
      // resolveStateCode,
    } = companyUseList({
      company_type: props.companyType,
      warehouseId:props.warehouseId,
      warehouseIdFrom:props.warehouseIdFrom,
      warehouseIdTo:props.warehouseIdTo,
      showAllLogistics:props.showAllLogistics,
      isAllState: props.isAllState,
      storeId:props.storeId,
      companyStoreId:props.companyStoreId,
      all:props.all,
      status:props.status,
      type:props.type,
      query_store:props.query_store,
      setting_ban_type:props.setting_ban_type
    })



    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      /*resolveProductTypeCode,
      resolveStateCode,*/
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
